/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import LogoImage from 'src/assets/images/MobileContact_Logo.png';
import { styled } from '@mui/material/styles';
import IrmaArtwork3FrameImage from 'src/assets/images/MobileContact_Irma_Artwork3_frame.png';
import useMobileContact from 'src/components/MobileContact/useMobileContact';

const MobileContact1 = styled('div')({
  left: `50%`,
  transform: `translate(-50%)`,
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `0px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  maxWidth: `799px`,
  height: `1261px`,
  justifyContent: `space-evenly`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Logo = styled('img')({
  height: `90px`,
  width: `249px`,
  objectFit: `cover`,
  position: `absolute`,
  //left: `71px`,
  top: `40px`,
});

const NavBarRectangle = styled('div')({
  backgroundColor: `rgba(217, 217, 217, 1)`,
  borderRadius: `0px`,
  //width: `390px`,
  width: `100%`,
  height: `30px`,
  position: `absolute`,
  //left: `0px`,
  top: `160px`,
});

const NavBar = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `space-evenly`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `241px`,
  width: `100%`,
  height: `15px`,
  //left: `67px`,
  top: `3px`,
});

const About = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //position: `absolute`,
  //left: `0px`,
  //top: `0px`,
  cursor: `pointer`,
});

const Home = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //position: `absolute`,
  //left: `188px`,
  //top: `0px`,
  cursor: `pointer`,
});

const Frame1 = styled('div')({
  borderRadius: `0px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `390px`,
  //width: `100%`,
  height: `412px`,
  //left: `-10px`,
  top: `660px`,
});

const IrmaArtwork3Frame = styled('div')({
  backgroundImage: `url(${IrmaArtwork3FrameImage})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  boxShadow: `-40px 44px 60px rgba(48, 50, 52, 0.15)`,
  borderRadius: `0px`,
  //width: `313px`,
  width: `100%`,
  height: `315px`,
  position: `relative`,
  //left: `48px`,
  //top: `10px`,
});

const ArtAdvisory = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `40px`,
  letterSpacing: `4px`,
  textDecoration: `none`,
  textTransform: `none`,
  WebkitTextStroke: `2px rgba(251, 252, 253, 0.94)`,
  width: `236px`,
  //width: `100%`,
  height: `84px`,
  position: `absolute`,
  left: `77px`,
  top: `250px`,
});

const IrmaPaulon = styled('div')({
  textAlign: `right`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.52)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `15px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `relative`,
  left: `260px`,
  top: `20px`,
});

const ContactUsFrame = styled('div')({
  backgroundColor: `rgba(229, 229, 229, 1)`,
  //boxShadow: `inset -7.699999809265137px 7.699999809265137px 7.699999809265137px rgba(255, 255, 255, 0.1), inset 7.699999809265137px -7.699999809265137px 7.699999809265137px rgba(149, 149, 149, 0.1)`,
  //backdropFilter: `blur(15.399999618530273px)`,
  //WebkitBackdropFilter: `blur(15.399999618530273px)`,
  border: `8px solid rgba(229, 229, 229, 0.54)`,
  boxSizing: `border-box`,
  borderRadius: `0px`,
  //width: `390px`,
  width: `100%`,
  height: `437px`,
  position: `absolute`,
  //left: `0px`,
  top: `210px`,
});

const ContactUs = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `305px`,
  width: `100%`,
  height: `287px`,
  //left: `38px`,
  top: `20px`,
});

const ContactUsHeader = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(48, 50, 52, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `36px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //width: `193.4px`,
  width: `100%`,
  height: `47.8px`,
  position: `absolute`,
  //left: `56px`,
  top: `0px`,
});

const NameText = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.94)`,
  borderRadius: `10px`,
  width: `305px`,
  height: `31px`,
  position: `absolute`,
  //left: `0px`,
  top: `73px`,
});

const EmailText = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.94)`,
  borderRadius: `10px`,
  width: `305px`,
  height: `31px`,
  position: `absolute`,
  //left: `0px`,
  top: `129px`,
});

const LeaveMessageText = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.94)`,
  borderRadius: `10px`,
  width: `305px`,
  height: `102px`,
  position: `absolute`,
  //left: `0px`,
  top: `185px`,
});

const Name = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.5)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `53px`,
  height: `9px`,
  position: `absolute`,
  left: `0px`,
  top: `5px`,
});

const Email = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.5)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `54px`,
  height: `10px`,
  position: `absolute`,
  left: `0px`,
  top: `5px`,
});

const LeaveAMessage = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.5)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `134px`,
  height: `10px`,
  position: `absolute`,
  left: `0px`,
  top: `5px`,
});

const SubmitButton = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `space-evenly`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `88px`,
  height: `37px`,
  //left: `147px`,
  top: `320px`,
});

const Rectangle9 = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.65)`,
  borderRadius: `10px`,
  width: `88px`,
  height: `37px`,
  position: `absolute`,
  //left: `0px`,
  top: `0px`,
});

const SubmitButton1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `700`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `80px`,
  height: `19px`,
  position: `absolute`,
  left: `3px`,
  top: `6px`,
  cursor: `pointer`,
});

const Group1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  //isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `-116px`,
  top: `1150px`,
});

const Footer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `0px`,
  top: `0px`,
});

const Footer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `0px`,
  top: `0px`,
});

const PuraItalianaLlc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `137px`,
  height: `26px`,
  position: `absolute`,
  //left: `256px`,
  top: `18px`,
});

function MobileContact(props) {
  const { fns } = useMobileContact(props);

  return (
    <MobileContact1 className={props.className}>
      <Logo src={LogoImage} loading="lazy" alt={'Logo'} />
      <NavBarRectangle>
        <NavBar>
          <About onClick={fns.aboutClick}>{`About`}</About>
          <Home onClick={fns.homeClick}>{`Home`}</Home>
        </NavBar>
      </NavBarRectangle>
      <Frame1>
        <IrmaArtwork3Frame></IrmaArtwork3Frame>
        <ArtAdvisory>{`ART ADVISORY`}</ArtAdvisory>
        <IrmaPaulon>{`Irma Paulon`}</IrmaPaulon>
      </Frame1>
      <ContactUsFrame>
      <ContactUs>
        <ContactUsHeader>{`Contact Us `}</ContactUsHeader>
        <NameText id={'nameTxt'}>
          <Name>{`Name`}</Name>
        </NameText>
        <EmailText id={'emailTxt'}>
          <Email>{`Email`}</Email>
        </EmailText>
        <LeaveMessageText id={'msgTxt'}>
          <LeaveAMessage>{`Leave a message`}</LeaveAMessage>
        </LeaveMessageText>
        <SubmitButton>
          <Rectangle9>
            <SubmitButton1 onClick={fns.submitBtnClick}>{`Submit`}</SubmitButton1>
          </Rectangle9>
        </SubmitButton>
      </ContactUs>
      </ContactUsFrame>
      
      <Group1>
        <Footer>
          <Footer1>
            <PuraItalianaLlc>{`Pura Italiana LLC`}</PuraItalianaLlc>
          </Footer1>
        </Footer>
      </Group1>
    </MobileContact1>
  );
}

export default MobileContact;
