/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import LogoImage from 'src/assets/images/ContactPage_Logo.png';
import { styled } from '@mui/material/styles';
import IrmaArtwork3FrameImage from 'src/assets/images/ContactPage_Irma_Artwork3_frame.png';
import useContactPage from 'src/components/ContactPage/useContactPage';

const ContactPage1 = styled('div')({
  //left: `50%`,
  //transform: `translate(-50%)`,
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `0px`,
  display: `flex`,
  position: `relative`,
  //isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  maxWidth: `1512px`,
  height: `1000px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const IrmaArtwork3Frame = styled('div')({
  backgroundImage: `url(${IrmaArtwork3FrameImage})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  boxShadow: `-40px 44px 60px rgba(48, 50, 52, 0.15)`,
  borderRadius: `0px`,
  width: `592px`,
  height: `594px`,
  position: `absolute`,
  left: `690px`,
  top: `272px`,
});

const ContactUsFrame = styled('div')({
  backgroundColor: `rgba(229, 229, 229, 1)`,
  //boxShadow: `inset -7.699999809265137px 7.699999809265137px 7.699999809265137px rgba(255, 255, 255, 0.1), inset 7.699999809265137px -7.699999809265137px 7.699999809265137px rgba(149, 149, 149, 0.1)`,
  //boxShadow: `7px 7px rgba(149, 149, 149, 0.1)`,
  boxShadow: `-40px 44px 60px rgba(48, 50, 52, 0.15)`,
  backdropFilter: `blur(15.399999618530273px)`,
  WebkitBackdropFilter: `blur(15.399999618530273px)`,
  border: `8px solid rgba(229, 229, 229, 0.54)`,
  boxSizing: `border-box`,
  borderRadius: `0px`,
  width: `461px`,
  //width: `100%`,
  height: `594px`,
  position: `absolute`,
  left: `229px`,
  top: `272px`,
});

const ContactUs = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `285px`,
  //width: `100%`,
  height: `374px`,
  left: `317px`,
  top: `334px`,
});

const ContactUsHeader = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(48, 50, 52, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `36px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `263px`,
  height: `65px`,
  position: `absolute`,
  left: `14px`,
  top: `0px`,
});

const NameText = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.94)`,
  borderRadius: `10px`,
  width: `285px`,
  height: `43px`,
  position: `absolute`,
  left: `0px`,
  top: `83px`,
});

const EmailText = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.94)`,
  borderRadius: `10px`,
  width: `285px`,
  height: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `159px`,
});

const LeaveMessageText = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.94)`,
  borderRadius: `10px`,
  width: `285px`,
  height: `139px`,
  position: `absolute`,
  left: `0px`,
  top: `235px`,
});

const Name = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.5)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `49px`,
  height: `13px`,
  position: `absolute`,
  left: `18px`,
  top: `99px`,
});

const Email = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.5)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `48px`,
  height: `14px`,
  position: `absolute`,
  left: `17px`,
  top: `174px`,
});

const LeaveAMessage = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.5)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `172px`,
  height: `14px`,
  position: `absolute`,
  left: `4px`,
  top: `250px`,
});

const SubmitButton = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `88px`,
  height: `37px`,
  left: `408px`,
  top: `747px`,
});

const Rectangle9 = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.65)`,
  borderRadius: `10px`,
  width: `88px`,
  height: `37px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SubmitButton1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `700`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `80px`,
  height: `19px`,
  position: `absolute`,
  left: `4px`,
  top: `7px`,
  cursor: `pointer`,
});

const ArtAdvisory = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `64px`,
  letterSpacing: `6.4px`,
  textDecoration: `none`,
  textTransform: `none`,
  WebkitTextStroke: `2px rgba(251, 252, 253, 0.94)`,
  width: `665px`,
  //width: `100%`,
  height: `187px`,
  position: `absolute`,
  left: `653px`,
  top: `708px`,
});

const NavBar = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `252px`,
  //width: `100%`,
  height: `33px`,
  left: `1098px`,
  top: `103px`,
});

const About = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  cursor: `pointer`,
});

const Home = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `188px`,
  top: `0px`,
  cursor: `pointer`,
});

const Logo = styled('img')({
  height: `180px`,
  width: `499px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `113px`,
  top: `66px`,
});

const Footer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1512px`,
  //width: `100%`,
  height: `40px`,
  left: `0px`,
  top: `923px`,
});

const Footer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1512px`,
  //width: `100%`,
  height: `40px`,
  left: `0px`,
  top: `0px`,
});

const PuraItalianaLlc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `694px`,
  top: `45px`,
});

const IrmaPaulon = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.52)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `15px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `1198px`,
  top: `881px`,
});


function ContactPage(props) {
  const { fns } = useContactPage();

  return (
    <ContactPage1 className={props.className}>
      <IrmaArtwork3Frame></IrmaArtwork3Frame>
      <ContactUsFrame></ContactUsFrame>
      <ContactUs>
        <ContactUsHeader>{`Contact Us `}</ContactUsHeader>
        <NameText id={'nameTxt'}></NameText>
        <EmailText id={'emailTxt'}></EmailText>
        <LeaveMessageText id={'msgTxt'}></LeaveMessageText>
        <Name>{`Name`}</Name>
        <Email>{`Email`}</Email>
        <LeaveAMessage>{`Leave a message`}</LeaveAMessage>
      </ContactUs>
      <SubmitButton>
        <Rectangle9></Rectangle9>
        <SubmitButton1 onClick={fns.submitBtnClick}>{`Submit`}</SubmitButton1>
      </SubmitButton>
      <ArtAdvisory>{`ART ADVISORY`}</ArtAdvisory>
      <NavBar>
        <About onClick={fns.aboutClick}>{`About`}</About>
        <Home onClick={fns.homeClick}>{`Home`}</Home>
      </NavBar>
      <Logo src={LogoImage} loading="lazy" alt={'Logo'} />
      <Footer>
        <Footer1>
          <PuraItalianaLlc>{`Pura Italiana LLC`}</PuraItalianaLlc>
        </Footer1>
      </Footer>
      <IrmaPaulon>{`Irma Paulon`}</IrmaPaulon>
    </ContactPage1>
  );
}

export default ContactPage;
