/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import LogoImage from 'src/assets/images/MobileHome_Logo.png';
import IrmaArtwork2FrameImage from 'src/assets/images/MobileHome_Irma_Artwork2_Frame.png';
import IrmaArtwork1FrameImage from 'src/assets/images/MobileHome_Irma_Artwork1_Frame.png';
import { styled } from '@mui/material/styles';
import useMobileHome from 'src/components/MobileHome/useMobileHome';

const MobileHome1 = styled('div')({
  left: `50%`,
  transform: `translate(-50%)`,
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `0px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  width: '100%',
  maxWidth: `799px`,
  height: `1109px`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Logo = styled('img')({
  height: `90px`,
  width: `249px`,
  objectFit: `cover`,
  position: `absolute`,
  
  top: `40px`,
});

const IrmaArtwork2 = styled('div')({
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `100%`,
  //height: `161.39px`,
  
  top: `220px`,
});

const IrmaArtwork2Frame = styled('img')({
  height: `243px`,
  width: `338px`,
  objectFit: `cover`,
  position: `absolute`,
  //left: `0px`,
  top: `0px`,
});

const IrmaArtwork1 = styled('div')({
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `100%`,
  //height: `201.84px`,
  
  top: `665px`,
});

const IrmaArtwork1Frame = styled('img')({
  height: `356px`,
  width: `295px`,
  objectFit: `cover`,
  position: `absolute`,
  //left: `0px`,
  //top: `0px`,
});

const IrmaPaulon = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.52)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `15px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `280px`,
  top: `865px`,
});

const Group1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `-116px`,
  //top: `1083px`,
  top: `1000px`,
});

const Footer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `0px`,
  top: `0px`,
});

const Footer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `0px`,
  top: `0px`,
});

const PuraItalianaLlc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `137px`,
  height: `26px`,
  position: `absolute`,
  //left: `256px`,
  //top: `18px`,
});

const NavBarRectangle = styled('div')({
  backgroundColor: `rgba(217, 217, 217, 1)`,
  borderRadius: `0px`,
  width: `100%`,
  height: `30px`,
  position: `absolute`,
  
  top: `160px`,
});

const NavBar = styled('div')({
  display: `flex`,
  position: `absolute`,
  //isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `space-evenly`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `100%`,
  height: `15px`,
  //left: `67px`,
  top: `8px`,
});

const About = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //position: `absolute`,
  //left: `0px`,
  //top: `0px`,
  cursor: `pointer`,
});

const Contact = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //position: `absolute`,
  //left: `188px`,
  //top: `0px`,
  cursor: `pointer`,
});

function MobileHome(props) {
  const { fns } = useMobileHome();

  return (
    <MobileHome1 className={props.className}>
      <Logo src={LogoImage} loading="lazy" alt={'Logo'} />

      <IrmaArtwork2>
        <IrmaArtwork2Frame
          src={IrmaArtwork2FrameImage}
          loading="lazy"
          alt={'Irma Artwork2 Frame'}
        />
      </IrmaArtwork2>

      <IrmaArtwork1>
        <IrmaArtwork1Frame
          src={IrmaArtwork1FrameImage}
          loading="lazy"
          alt={'Irma Artwork1 Frame'}
        />
      </IrmaArtwork1>

      <IrmaPaulon>{`Irma Paulon`}</IrmaPaulon>

      <NavBarRectangle>
        <NavBar>
          <About onClick={fns.aboutClick}>{`About`}</About>
          <Contact onClick={fns.contactClick}>{`Contact`}</Contact>
        </NavBar>
      </NavBarRectangle>

      <Group1>
        <Footer>
          <Footer1>
            <PuraItalianaLlc>{`Pura Italiana LLC`}</PuraItalianaLlc>
          </Footer1>
        </Footer>
      </Group1>

    </MobileHome1>
  );
}

export default MobileHome;
