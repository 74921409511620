/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *   Do not change the name of the hook
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useNavigate } from 'react-router-dom';
const useMobileContactMessage = () => {
  const navigate = useNavigate();
  const aboutClick = (e) => {
    try {
      console.log(`MobileContactMessage.js - navigating to about component`);

      navigate('/about');
    } catch (err) {
      console.log(
        `MobileContactMessage.js - about click eventhandler - error -> ${err}`
      );
    }
  };
  const contactClick = (e) => {
    try {
      console.log(`MobileContactMessage.js - navigating to contact component`);

      navigate('/contact');
    } catch (err) {
      console.log(
        `MobileContactMessage.js - contact click eventhandler - error -> ${err}`
      );
    }
  };
  const homeBtnClick = (e) => {
    try {
      console.log(`MobileContactMessage.js - navigating to homepage component`);

      navigate('/');
    } catch (err) {
      console.log(
        `MobileContactMessage.js - home click eventhandler - error -> ${err}`
      );
    }
  };

  const data = {};
  const AboutClick = () => {};

  const fns = { AboutClick, aboutClick, contactClick, homeBtnClick };

  return { data, fns };
};

export default useMobileContactMessage;
