/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *   Do not change the name of the hook
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const useContactPage = () => {
  const navigate = useNavigate();
  const aboutClick = (e) => {
    try {
      console.log(`Contact.js - navigating to about component`);

      navigate('/about');
    } catch (err) {
      console.log(`Contact.js about click eventhandler - error -> ${err}`);
    }
  };
  const homeClick = (e) => {
    try {
      console.log(`Contact.js - navigating to homepage component`);

      navigate('/');
    } catch (err) {
      console.log(`Contact.js - home click eventhandler - error -> ${err}`);
    }
  };
  const submitBtnClick = (e) => {
    try {
      console.log(`Contact.js - form submission button`);
      sendGet({});
      navigate('/contactMsg');
    } catch (err) {
      console.log(`Contact.js - form submission button - error -> ${err}`);
    }
  };

  /* persist category dataset */
  const sendGet = async (formData) => {

    console.log(`sendGet -> formData -> ${JSON.stringify(formData)}`);

    const url = "http://puraitaliana.com:5000";
    const options =  {
        //method:"POST",
        method:"GET",
        //body: JSON.stringify(formData),
        //headers: {'Content-Type':'application/json'}
    };

    /* make call to server */
    await fetch(url,options)
    .then((res) => {
      let promise = res.json();
console.log(`inside fetch - first then statement - Response promise object -> ${promise}`);
promise.then(() => {
  console.log(`it is still not working`);
});
        //return promise
        return promise;
      }).then((json)=> {

console.log(`inside fetch - second then statement`);

        console.log(`Contact Page - sendGet - json -> ${json}`);

        /** if request returns in a failed state */
        //if(json.state === undefined || json.state === "failed"){

          //console.log(`inside if failed state`);
          //console.log(`json.statusCode -> ${json.statusCode}`);

            
              navigate("/");
        //}else{//end if insert request returned in a failed state
         //navigate("/contactMsg");
        //}
      }).catch((err) => {
        console.log(`sendGet - inside promise catch clause - error -> ${err}`);
        return;
      });
    
};//end sendGet

  useEffect(() => {
    console.log(`inside useEffect of ContactPage useContactPage code`);
  }, []);

  const data = {};

  const fns = { aboutClick, homeClick, submitBtnClick };

  return { data, fns };
};

export default useContactPage;
