/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import ProfileImageFrameImage from 'src/assets/images/About_Profile_Image_Frame.png';
import LogoImage from 'src/assets/images/About_Logo.png';
import { styled } from '@mui/material/styles';
import useAbout from 'src/components/About/useAbout';

const About1 = styled('div')({
  left: `50%`,
  transform: `translate(-50%)`,
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `0px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  maxWidth: `1512px`,
  height: `982px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const ProfileImage = styled('div')({
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  borderRadius: `5px`,
  width: `435px`,
  height: `667px`,
  left: `893px`,
  top: `199px`,
});

const ProfileImageFrame = styled('img')({
  height: `667px`,
  width: `435px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  borderRadius: `5px`,
});

const Logo = styled('img')({
  height: `180px`,
  width: `499px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `113px`,
  top: `66px`,
});

const Footer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `100%`,
  height: `40px`,
  left: `0px`,
  top: `923px`,
});

const Footer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `100%`,
  height: `40px`,
  left: `0px`,
  top: `0px`,
});

const Line3 = styled('div')({
  border: `1px solid rgba(0, 0, 0, 1)`,
  width: `100%`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  maxWidth: `1512px`
});

const PuraItalianaLlc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `124px`,
  position: `absolute`,
  left: `694px`,
  top: `18px`,
});

const NancyDawsonIsTheVisi = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `594.5px`,
  height: `326px`,
  position: `absolute`,
  left: `121px`,
  top: `346px`,
});

const NavBar = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `271px`,
  height: `33px`,
  left: `1098px`,
  top: `103px`,
});

const Home = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  cursor: `pointer`,
});

const Contact = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `188px`,
  top: `0px`,
  cursor: `pointer`,
});

function About(props) {
  const { fns } = useAbout();

  return (
    <About1 className={props.className}>
      <ProfileImage>
        <ProfileImageFrame
          src={ProfileImageFrameImage}
          loading="lazy"
          alt={'Profile Image Frame'}
        />
      </ProfileImage>
      <Logo src={LogoImage} loading="lazy" alt={'Logo'} />
      <Footer>
        <Footer1>
          <PuraItalianaLlc>{`Pura Italiana LLC`}</PuraItalianaLlc>
        </Footer1>
      </Footer>
      <NancyDawsonIsTheVisi>
        {`Nancy Dawson is the visionary curator behind Pura Italiana. Her journey into the art world began through the process of acquiring Italian citizenship and was fueled by the passion and desire to bridge Italian artistic talents to the US market. Nancy has an impeccable eye for seasoned and emerging artists bringing a unique perspective invaluable to her clients. Dedicated to elevating the art world by fostering meaningful connections between artists and art enthusiasts, Pura Italiana offers a range of services focusing on the representation of artists around the world.`}
      </NancyDawsonIsTheVisi>
      <NavBar>
        <Home onClick={fns.homeClick}>{`Home`}</Home>
        <Contact onClick={fns.contactClick}>{`Contact`}</Contact>
      </NavBar>
    </About1>
  );
}

export default About;
