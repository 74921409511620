/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import IrmaArtwork2FrameImage from 'src/assets/images/Homepage_Irma_Artwork2_Frame.png';
import IrmaArtwork1FrameImage from 'src/assets/images/Homepage_Irma_Artwork1_Frame.png';
import LogoImage from 'src/assets/images/Homepage_Logo.png';
import { styled } from '@mui/material/styles';
import useHomepage from 'src/components/Homepage/useHomepage';

const Homepage1 = styled('div')({
  left: '50%',
  transform: 'translate(-50%)',
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `0px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  maxWidth: `1512px`,
  height: `982px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Footer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `100%`,
  height: `40px`,
  left: `0px`,
  top: `923px`,
});

const Footer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `100%`,
  height: `40px`,
  left: `0px`,
  top: `0px`,
});

const Line3 = styled('div')({
  border: `1px solid rgba(0, 0, 0, 1)`,
  width: `100%`,
  height: `0px`,
  position: `absolute`,
  maxWidth:`1512px`,
  left: `0px`,
  top: `0px`,
});

const PuraItalianaLlc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `124px`,
  position: `absolute`,
  left: `694px`,
  top: `18px`,
});

const Frame2 = styled('div')({
  borderRadius: `0px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1022px`,
  height: `525.26px`,
  left: `245px`,
  top: `301px`,
});

const IrmaArtwork2 = styled('div')({
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `586px`,
  height: `420px`,
  margin: `0px`,
});

const IrmaArtwork2Frame = styled('img')({
  height: `420px`,
  width: `586px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const IrmaArtwork1 = styled('div')({
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `436px`,
  height: `525.26px`,
  margin: `0px`,
});

const IrmaArtwork1Frame = styled('img')({
  height: `525.26px`,
  width: `436px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Logo = styled('img')({
  height: `180px`,
  width: `499px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `113px`,
  top: `66px`,
});

const NavBar = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `271px`,
  height: `33px`,
  left: `1098px`,
  top: `103px`,
});

const About = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  cursor: `pointer`,
});

const Contact = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `188px`,
  top: `0px`,
  cursor: `pointer`,
});

const IrmaPaulon = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.52)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `15px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `1183px`,
  top: `853px`,
});

function Homepage(props) {
  const { fns } = useHomepage(props);

  return (
    <Homepage1 className={props.className}>
      <Footer>
        <Footer1>
          <PuraItalianaLlc>{`Pura Italiana LLC`}</PuraItalianaLlc>
        </Footer1>
      </Footer>
      <Frame2>
        <IrmaArtwork2>
          <IrmaArtwork2Frame
            src={IrmaArtwork2FrameImage}
            loading="lazy"
            alt={'Irma Artwork2 Frame'}
          />
        </IrmaArtwork2>
        <IrmaArtwork1>
          <IrmaArtwork1Frame
            src={IrmaArtwork1FrameImage}
            loading="lazy"
            alt={'Irma Artwork1 Frame'}
          />
        </IrmaArtwork1>
      </Frame2>
      <Logo src={LogoImage} loading="lazy" alt={'Logo'} />
      <NavBar>
        <About onClick={fns.aboutClick}>{`About`}</About>
        <Contact onClick={fns.contactClick}>{`Contact`}</Contact>
      </NavBar>
      <IrmaPaulon>{`Irma Paulon`}</IrmaPaulon>
    </Homepage1>
  );
}

export default Homepage;
