/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import ProfileImageFrameImage from 'src/assets/images/MobileAbout_Profile_Image_Frame.png';
import LogoImage from 'src/assets/images/MobileAbout_Logo.png';
import { styled } from '@mui/material/styles';
import useMobileAbout from 'src/components/MobileAbout/useMobileAbout';

const MobileAbout1 = styled('div')({
  left: `50%`,
  transform: `translate(-50%)`,
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `0px`,
  display: `flex`,
  position: `relative`,
  //isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  maxWidth: `799px`,
  height: `1250px`,
  //justifyContent: `flex-start`,
  justifyContent: `space-evenly`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const NancyDawsonIsTheVisiSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `25px`,
  textTransform: `none`,
});

const NancyDawsonIsTheVisiSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `25px`,
  textTransform: `none`,
});

const NancyDawsonIsTheVisi = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `nullpx`,
  letterSpacing: `NaNpx`,
  textDecoration: `none`,
  lineHeight: `25px`,
  textTransform: `none`,
  width: `318px`,
  height: `336px`,
  position: `absolute`,
  //left: `36px`,
  top: `670px`,
});

const ProfileImage = styled('div')({
  //boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)`,
  
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `194px`,
  //height: `297.47px`,
  //left: `98px`,
  width:`100%`,
  top: `230px`,
});

const ProfileImageFrame = styled('img')({
  height: `410px`,
  width: `280px`,
  objectFit: `cover`,
  position: `absolute`,
  borderRadius: `5px`,
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.75)`,
  //left: `0px`,
  //top: `0px`,
});

const Group1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `-116px`,
  top: `1083px`,
});

const Footer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `0px`,
  top: `0px`,
});

const Footer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `0px`,
  top: `0px`,
});

const PuraItalianaLlc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `137px`,
  height: `26px`,
  position: `absolute`,
  //left: `256px`,
  //top: `18px`,
});

const Logo = styled('img')({
  height: `90px`,
  width: `249px`,
  objectFit: `cover`,
  position: `absolute`,
  //left: `71px`,
  top: `40px`,
});

const NavBarRectangle = styled('div')({
  backgroundColor: `rgba(217, 217, 217, 1)`,
  borderRadius: `0px`,
  //width: `390px`,
  width: `100%`,
  //height: `46px`,
  height: `30px`,
  position: `absolute`,
  //left: `0px`,
  top: `160px`,
});

const NavBar = styled('div')({
  display: `flex`,
  position: `absolute`,
  //isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `space-evenly`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `257px`,
  width: `100%`,
  //height: `28px`,
  height: `15px`,
  //left: `67px`,
  top: `8px`,
});

const Home = styled('div')({
  //textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //position: `absolute`,
  //left: `0px`,
  //top: `0px`,
  cursor: `pointer`,
});

const Contact = styled('div')({
  //textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //position: `absolute`,
  //left: `188px`,
  //top: `0px`,
  cursor: `pointer`,
});

function MobileAbout(props) {
  const { fns } = useMobileAbout();

  return (
    <MobileAbout1 className={props.className}>
      <Logo src={LogoImage} loading="lazy" alt={'Logo'} />
      <NavBarRectangle>
        <NavBar>
          <Home onClick={fns.homeClick}>{`Home`}</Home>
          <Contact onClick={fns.contactClick}>{`Contact`}</Contact>
        </NavBar>
      </NavBarRectangle>
    
      <NancyDawsonIsTheVisi>
        <NancyDawsonIsTheVisiSpan1>{`Nancy Dawson`}</NancyDawsonIsTheVisiSpan1>
        <NancyDawsonIsTheVisiSpan2>{` is the visionary curator behind Pura Italiana. Her journey into the art world began through the process of acquiring Italian citizenship and was fueled by the passion and desire to bridge Italian artistic talents to the US market. Nancy has an impeccable eye for seasoned and emerging artists bringing a unique perspective invaluable to her clients. Dedicated to elevating the art world by fostering meaningful connections between artists and art enthusiasts, Pura Italiana offers a range of services focusing on the representation of artists around the world.`}</NancyDawsonIsTheVisiSpan2>
      </NancyDawsonIsTheVisi>
      <ProfileImage>
        <ProfileImageFrame
          src={ProfileImageFrameImage}
          loading="lazy"
          alt={'Profile Image Frame'}
        />
      </ProfileImage>
      <Group1>
        <Footer>
          <Footer1>
            <PuraItalianaLlc>{`Pura Italiana LLC`}</PuraItalianaLlc>
          </Footer1>
        </Footer>
      </Group1>
    </MobileAbout1>
  );
}

export default MobileAbout;
