/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *   Do not change the name of the hook
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useNavigate } from 'react-router-dom';
const useMobileAbout = () => {
  const navigate = useNavigate();
  const homeClick = (e) => {
    try {
      console.log(`MobileAbout.js - navigating to homepage component`);

      navigate('/');
    } catch (err) {
      console.log(`MobileAbout.js - home click eventhandler - error -> ${err}`);
    }
  };
  const contactClick = (e) => {
    try {
      console.log(`MobileAbout.js - navigating to contact component`);

      navigate('/contact');
    } catch (err) {
      console.log(
        `MobileAbout.js - contact click eventhandler - error -> ${err}`
      );
    }
  };

  const data = {};

  const fns = { homeClick, contactClick };

  return { data, fns };
};

export default useMobileAbout;
