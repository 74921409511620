import React, {useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import questTheme from 'src/MyDesignSystemLightTheme';
import Homepage from 'src/components/Homepage/Homepage';
import ContactPage from './components/ContactPage/ContactPage';
import About from './components/About/About';
import ContactUsMessage from './components/ContactUsMessage/ContactUsMessage';
import MobileAbout from './components/MobileAbout/MobileAbout';
import MobileContact from './components/MobileContact/MobileContact';
import MobileContactMessage from './components/MobileContactMessage/MobileContactMessage';
import MobileHome from './components/MobileHome/MobileHome';
import { is } from '@react-spring/shared';

function App() {
  const navigate = useNavigate();

  let isMobile = (window.innerWidth < 800);

  useEffect(()=> {
    console.log(`inside onload eventhandler`);
    isMobile = (window.innerWidth < 799);
    window.addEventListener('resize', trackViewportWidth);

    return ()=>{
      console.log(`------------------------inside page unload eventhandler`);
      window.removeEventListener("resize",trackViewportWidth);
    }
  },[]);

  /** window resize eventhandler */
  const trackViewportWidth = (e)=>{
    console.log(`current url -> ${window.location.href}`);
    console.log(`current path -> ${window.location.pathname}`);
    if(window.innerWidth < 800){
      if(!isMobile){
        console.log(`inside isMobile equals false`);
        isMobile = true;
        navigate(window.location.pathname);
      }
    }else{
        if(isMobile){
          console.log(`inside isMobile equals true`);
          isMobile = false;
          navigate(window.location.pathname);
        }
      }
  };


  //if(isMobile){
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={questTheme}>
          <Routes>
            <Route path="/" element={(window.innerWidth<799)?<MobileHome/>:<Homepage/>}/>
            <Route path="/about" element={(window.innerWidth<799)?<MobileAbout/>:<About/>}/>
            <Route path="/contact" element={(window.innerWidth<799)?<MobileContact/>:<ContactPage/>}/>
            <Route path="/contactMsg" element={(window.innerWidth<799)?<MobileContactMessage/>:<ContactUsMessage/>}/>
          </Routes>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
};//end App.jsx declaration

export default App;
