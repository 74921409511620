/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import LogoImage from 'src/assets/images/MobileContactMessage_Logo.png';
import { styled } from '@mui/material/styles';
import IrmaArtwork3FrameImage from 'src/assets/images/MobileContactMessage_Irma_Artwork3_frame.png';
import useMobileContactMessage from 'src/components/MobileContactMessage/useMobileContactMessage';

const MobileContactMessage1 = styled('div')({
  left: `50%`,
  transform: `translate(-50%)`,
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `0px`,
  display: `flex`,
  position: `relative`,
  //isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  maxWidth: `799px`,
  height: `1110px`,
  justifyContent: `space-evenly`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Logo = styled('img')({
  height: `90px`,
  width: `249px`,
  objectFit: `cover`,
  position: `absolute`,
  //left: `71px`,
  top: `40px`,
});

const NavBarRectangle = styled('div')({
  backgroundColor: `rgba(217, 217, 217, 1)`,
  borderRadius: `0px`,
  //width: `390px`,
  width: `100%`,
  height: `30px`,
  position: `absolute`,
  //left: `0px`,
  top: `160px`,
});

const NavBar = styled('div')({
  display: `flex`,
  position: `absolute`,
  //isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `space-evenly`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `257px`,
  width: `100%`,
  height: `15px`,
  //left: `67px`,
  top: `8px`,
});

const About = styled('div')({
  //textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //position: `absolute`,
  //left: `0px`,
  //top: `0px`,
  cursor: `pointer`,
});

const Contact = styled('div')({
  //textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //position: `absolute`,
  //left: `188px`,
  //top: `0px`,
  cursor: `pointer`,
});

const ContactUsFrame = styled('div')({
  display: `flex`,
  position: `relative`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  backgroundColor: `rgba(229, 229, 229, 1)`,
  //boxShadow: `inset -7.699999809265137px 7.699999809265137px 7.699999809265137px rgba(255, 255, 255, 0.1), inset 7.699999809265137px -7.699999809265137px 7.699999809265137px rgba(149, 149, 149, 0.1)`,
  //backdropFilter: `blur(15.399999618530273px)`,
  WebkitBackdropFilter: `blur(15.399999618530273px)`,
  border: `8px solid rgba(229, 229, 229, 0.54)`,
  boxSizing: `border-box`,
  borderRadius: `0px`,
  //width: `390px`,
  width: `100%`,
  //height: `225px`,
  height: `250px`,
  position: `absolute`,
  //left: `0px`,
  top: `215px`,
});

const ContactUs = styled('div')({
  display: `block`,
  //position: `absolute`,
  isolation: `isolate`,
  //flexDirection: `column`,
  //justifyContent: `flex-start`,
  //alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `263px`,
  width: `70%`,
  height: `100px`,
  //left: `61px`,
  //top: `15px`,
});

const ContactUsHeader = styled('div')({
  marginTop: `25px`,
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(48, 50, 52, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  //width: `263px`,
  //width: `100%`,
  //height: `65px`,
  //position: `absolute`,
  //left: `0px`,
  //top: `0px`,
});

const SubmitButton = styled('div')({
  marginTop: `50px`,
  marginBottom: `25px`,
  //display: `flex`,
  //position: `absolute`,
  isolation: `isolate`,
  //flexDirection: `column`,
  //justifyContent: `center`,
  //alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `88px`,
  //height: `37px`,
  //left: `149px`,
  //top: `200px`,
});

const Rectangle9 = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.65)`,
  borderRadius: `10px`,
  width: `88px`,
  height: `37px`,
  //position: `absolute`,
  //left: `0px`,
  top: `0px`,
});

const SubmitButton1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `700`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `80px`,
  height: `10px`,
  position: `relative`,
  left: `3px`,
  top: `6px`,
  cursor: `pointer`,
});

const FooterGroup = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `-119px`,
  top: `1000px`,
});

const Footer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `0px`,
  top: `0px`,
});

const Footer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `623px`,
  width: `100%`,
  height: `44px`,
  //left: `0px`,
  top: `0px`,
});

const Frame1 = styled('div')({
  borderRadius: `0px`,
  display: `flex`,
  //display: `block`,
  position: `relative`,
  justifyContent: `center`,
  isolation: `isolate`,
  //flexDirection: `row`,
  //justifyContent: `flex-start`,
  //alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  //width: `410px`,
  width: `100%`,
  height: `402px`,
  //left: `-9px`,
  top: `500px`,
});

const IrmaArtwork3Frame = styled('div')({
  backgroundImage: `url(${IrmaArtwork3FrameImage})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  boxShadow: `-40px 44px 60px rgba(48, 50, 52, 0.15)`,
  borderRadius: `0px`,
  width: `313px`,
  //width: `100%`,
  height: `356px`,
  //position: `absolute`,
  //left: `10px`,
  //top: `10px`,
});

const PuraItalianaLlc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `137px`,
  height: `26px`,
  position: `relative`,
  //left: `256px`,
  top: `18px`,
});


const IrmaPaulon = styled('div')({
  textAlign: `right`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.52)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `15px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `relative`,
  left: `-15px`,
  top: `380px`,
});

const ArtAdvisory = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `40px`,
  letterSpacing: `4px`,
  textDecoration: `none`,
  textTransform: `none`,
  WebkitTextStroke: `2px rgba(251, 252, 253, 0.94)`,
  //width: `665px`,
  width: `286px`,
  height: `84px`,
  position: `absolute`,
  //left: `-138px`,
  top: `250px`,
});

function MobileContactMessage(props) {
  const { fns } = useMobileContactMessage();

  return (
    <MobileContactMessage1 className={props.className}>
      <Logo src={LogoImage} loading="lazy" alt={'Logo'} />

      <NavBarRectangle>
        <NavBar>
          <About onClick={fns.aboutClick}>{`About`}</About>
          <Contact onClick={fns.contactClick}>{`Contact`}</Contact>
        </NavBar>
      </NavBarRectangle>

      <ContactUsFrame>
        <ContactUs>
          <ContactUsHeader>
            {`Thank you for your message!  We will be in touch with you soon.`}
          </ContactUsHeader>
        </ContactUs>

        <SubmitButton>
          <Rectangle9>
            <SubmitButton1 onClick={fns.homeBtnClick}>{`Home`}</SubmitButton1>
          </Rectangle9>
        </SubmitButton>

      </ContactUsFrame>

      <FooterGroup>
        <Footer>
          <Footer1>
            <PuraItalianaLlc>{`Pura Italiana LLC`}</PuraItalianaLlc>
          </Footer1>
        </Footer>
      </FooterGroup>

      <Frame1>
        <IrmaArtwork3Frame>
          <IrmaPaulon>{`Irma Paulon`}</IrmaPaulon>
          <ArtAdvisory>{`ART ADVISORY`}</ArtAdvisory>
        </IrmaArtwork3Frame>
      </Frame1>

    </MobileContactMessage1>
  );
}

export default MobileContactMessage;
