/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *   Do not change the name of the hook
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useNavigate } from 'react-router-dom';
const useMobileHome = () => {
  const navigate = useNavigate();
  const aboutClick = (e) => {
    try {
      console.log(`MobileHome.js - navigating to about component`);

      navigate('/about');
    } catch (err) {
      console.log(`MobileHome.js about click eventhandler - error -> ${err}`);
    }
  };
  const contactClick = (e) => {
    try {
      console.log(`MobileHome.js - navigating to contact component`);

      navigate('/contact');
    } catch (err) {
      console.log(
        `MobileHome.js - contact click eventhandler - error -> ${err}`
      );
    }
  };

  const data = {};

  const fns = { aboutClick, contactClick };

  return { data, fns };
};

export default useMobileHome;
