/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import LogoImage from 'src/assets/images/ContactUsMessage_Logo.png';
import { styled } from '@mui/material/styles';
import IrmaArtwork3FrameImage from 'src/assets/images/ContactUsMessage_Irma_Artwork3_frame.png';
import useContactUsMessage from 'src/components/ContactUsMessage/useContactUsMessage';

const ContactUsMessage1 = styled('div')({
  left: `50%`,
  transform: `translate(-50%)`,
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `0px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  maxWidth: `1512px`,
  height: `982px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const NavBar = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `271px`,
  height: `33px`,
  left: `1098px`,
  top: `103px`,
});

const About = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  cursor: `pointer`,
});

const Contact = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `188px`,
  top: `0px`,
  cursor: `pointer`,
});

const Logo = styled('img')({
  height: `180px`,
  width: `499px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `113px`,
  top: `66px`,
});

const Footer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1512px`,
  height: `40px`,
  left: `0px`,
  top: `923px`,
});

const Footer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1512px`,
  height: `40px`,
  left: `0px`,
  top: `0px`,
});

const Line3 = styled('div')({
  border: `1px solid rgba(0, 0, 0, 1)`,
  width: `1512px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const PuraItalianaLlc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `694px`,
  top: `18px`,
});

const IrmaArtwork3Frame = styled('div')({
  backgroundImage: `url(${IrmaArtwork3FrameImage})`,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  boxShadow: `-40px 44px 60px rgba(48, 50, 52, 0.15)`,
  borderRadius: `0px`,
  width: `592px`,
  height: `594px`,
  position: `absolute`,
  left: `690px`,
  top: `272px`,
});

const ContactUsFrame = styled('div')({
  backgroundColor: `rgba(229, 229, 229, 1)`,
  //boxShadow: `inset -7.699999809265137px 7.699999809265137px 7.699999809265137px rgba(255, 255, 255, 0.1), inset 7.699999809265137px -7.699999809265137px 7.699999809265137px rgba(149, 149, 149, 0.1)`,
  //backdropFilter: `blur(15.399999618530273px)`,
  boxShadow: `-40px 44px 60px rgba(48, 50, 52, 0.15)`,
  backdropFilter: `blur(15.399999618530273px)`,
  WebkitBackdropFilter: `blur(15.399999618530273px)`,
  border: `8px solid rgba(229, 229, 229, 0.54)`,
  boxSizing: `border-box`,
  borderRadius: `0px`,
  width: `461px`,
  height: `594px`,
  position: `absolute`,
  left: `229px`,
  top: `272px`,
});

const ContactUs = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `263px`,
  height: `65px`,
  left: `328px`,
  top: `491px`,
});

const ContactUsHeader = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(48, 50, 52, 1)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `36px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `263px`,
  height: `65px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SubmitButton = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `88px`,
  height: `37px`,
  left: `416px`,
  top: `749px`,
});

const Rectangle9 = styled('div')({
  backgroundColor: `rgba(251, 252, 253, 0.65)`,
  borderRadius: `10px`,
  width: `88px`,
  height: `37px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SubmitButton1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `700`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  width: `80px`,
  height: `19px`,
  position: `absolute`,
  left: `4px`,
  top: `6px`,
  cursor: `pointer`,
});

const IrmaPaulon = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 0.52)`,
  fontStyle: `normal`,
  fontFamily: `Scope One`,
  fontWeight: `400`,
  fontSize: `15px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `1198px`,
  top: `881px`,
});

const ArtAdvisory = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `64px`,
  letterSpacing: `6.4px`,
  textDecoration: `none`,
  textTransform: `none`,
  WebkitTextStroke: `2px rgba(251, 252, 253, 0.94)`,
  width: `665px`,
  height: `187px`,
  position: `absolute`,
  left: `664px`,
  top: `703px`,
});
function ContactUsMessage(props) {
  const { fns } = useContactUsMessage();

  return (
    <ContactUsMessage1 className={props.className}>
      <NavBar>
        <About onClick={fns.aboutClick}>{`About`}</About>
        <Contact onClick={fns.contactClick}>{`Contact`}</Contact>
      </NavBar>
      <Logo src={LogoImage} loading="lazy" alt={'Logo'} />
      <Footer>
        <Footer1>
          <PuraItalianaLlc>{`Pura Italiana LLC`}</PuraItalianaLlc>
        </Footer1>
      </Footer>
      <IrmaArtwork3Frame></IrmaArtwork3Frame>
      <ContactUsFrame></ContactUsFrame>
      <ContactUs>
        <ContactUsHeader>
          {`Thank you for your message!  We will be in touch with you soon.`}
        </ContactUsHeader>
      </ContactUs>
      <SubmitButton>
        <Rectangle9></Rectangle9>
        <SubmitButton1 onClick={fns.homeBtnClick}>{`Home`}</SubmitButton1>
      </SubmitButton>
      <IrmaPaulon>{`Irma Paulon`}</IrmaPaulon>
      <ArtAdvisory>{`ART ADVISORY`}</ArtAdvisory>
    </ContactUsMessage1>
  );
}

export default ContactUsMessage;
